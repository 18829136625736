const IMPACT_PARTNERS_BY_BASE_DOMAIN = [
  'target.com',
  'crateandbarrel.com',
  'quince.com',
  'walmart.com',
  'hannaandersson.com',
];
const RAKUTEN_PARTNERS_BY_BASE_DOMAIN = ['disabled-anthropologie.com'];
const AMAZON_TAG_WEDDINGS = 'zola0c-20';

const getBaseDomain = (url: string) => {
  try {
    const urlObject = new URL(url);
    const { hostname } = urlObject;

    // Split the hostname by dots
    const parts = hostname.split('.');
    const numParts = parts.length;

    // If there are at least two parts, the last two are likely the domain
    if (numParts >= 2) {
      return `${parts[numParts - 2]}.${parts[numParts - 1]}`;
    }
    // If there's only one part, it's the domain itself (e.g., localhost)
    return hostname;
  } catch (error) {
    // Handle invalid URLs
    return null;
  }
};

const manualRedirect = (url: string) => {
  window.open(url, '_blank', 'noopener, noreferrer')?.focus();
};

export const handleAffiliateLink = (url: string) => {
  if (typeof window !== 'undefined') {
    try {
      const normalizedUrl = url.toLowerCase();
      const baseDomain = getBaseDomain(normalizedUrl);

      // We can't do anything if it doesn't have a base domain
      if (!baseDomain) {
        return;
      }

      // Affiliate links handling
      // Trust the partner scripts to do the right thing
      if (
        IMPACT_PARTNERS_BY_BASE_DOMAIN.includes(baseDomain) ||
        RAKUTEN_PARTNERS_BY_BASE_DOMAIN.includes(baseDomain)
      ) {
        const link = document.body.appendChild(
          Object.assign(document.createElement('a'), {
            href: url,
            target: '_blank',
            rel: 'noopener noreferrer nofollow',
          })
        );
        link.click();
        link.remove();
        return;
      }

      // Amazon
      // No need to account for Baby yet since there are no external links
      if (normalizedUrl.includes('amazon.com')) {
        const hasExistingParams = normalizedUrl.includes('?');
        const appendMethod = hasExistingParams ? '&' : '?';
        const amazonTag = `tag=${AMAZON_TAG_WEDDINGS}`;
        manualRedirect(`${url}${appendMethod}${amazonTag}`);
        return;
      }

      // Hilton - Fix for book/reservation links not working with Souvrn
      if (normalizedUrl.includes('hilton.com') && normalizedUrl.includes('book/reservation')) {
        manualRedirect(url);
        return;
      }

      // Souvrn - Catch-all
      window.vglnk.open(url, '_blank');
    } catch (err) {
      // If anything goes wrong, we just open the original link
      manualRedirect(url);
    }
  }
};
